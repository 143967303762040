import {CartService} from './CartService';

export class CashierExpressService {
  private readonly cartService: CartService;

  constructor({cartService}: {cartService: CartService}) {
    this.cartService = cartService;
  }

  public getOrderItems() {
    return this.cartService.cart.items.map((item) => ({
      id: item.cartItemId.toString(),
      name: item.product.name,
      price: item.product.price.toString(),
      quantity: item.quantity,
      category: item.product.productType as 'physical' | 'digital',
    }));
  }
}
