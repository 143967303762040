import {ICheckoutShippingRuleOption} from '@wix/wixstores-graphql-schema';
import {CartService} from './CartService';
import {DestinationCompleteness} from '../stores/OrderStore';
import {StyleSettingsService} from './StyleSettingsService';
import {StoreSettingsService} from './StoreSettingsService';

export type ShippingRuleOptionModel = ICheckoutShippingRuleOption & {isPickup: boolean};

export class OrderService {
  private readonly cartService: CartService;
  private readonly styleSettingsService: StyleSettingsService;
  private readonly storeSettingsService: StoreSettingsService;
  public destinationWasChanged: boolean = false;

  constructor({
    cartService,
    styleSettingsService,
    storeSettingsService,
  }: {
    cartService: CartService;
    styleSettingsService: StyleSettingsService;
    storeSettingsService: StoreSettingsService;
  }) {
    this.cartService = cartService;
    this.styleSettingsService = styleSettingsService;
    this.storeSettingsService = storeSettingsService;
  }

  private get cart() {
    return this.cartService.cart;
  }

  public get canShipToDestination() {
    return this.cartService.cart.shippingRuleInfo?.canShipToDestination;
  }

  public get hasShippingRules(): boolean {
    return !!this.cartService.cart.shippingRuleInfo?.shippingRule?.options.length;
  }

  public get canShip() {
    /* istanbul ignore next */
    return (
      this.hasShippingRules ||
      //todo(guy segev): test delivery error for digital (cart.errors.cant_sell_to_country?)
      /* istanbul ignore next */
      this.cartService.isDigitalCart ||
      /* istanbul ignore next */
      !this.destinationWasChanged ||
      //todo(guy segev): add test for DestinationCompleteness.SUBDIVISION
      /* istanbul ignore next */
      this.cartService.cart.destinationCompleteness.includes(DestinationCompleteness.SUBDIVISION)
    );
  }

  public static toShippingRuleOptionModel(option: ICheckoutShippingRuleOption): ShippingRuleOptionModel {
    return {
      ...option,
      isPickup: !!option.pickupInfo?.address?.addressLine,
    };
  }

  public get isPickup(): boolean {
    const {shouldShowShipping} = this.styleSettingsService;
    const selectedShippingRuleId = this.cart.selectedShippingOption?.id;

    return (
      shouldShowShipping &&
      selectedShippingRuleId &&
      /* istanbul ignore next - can't ignore the optional chaining :( */
      this.cart.shippingRuleInfo?.shippingRule?.options
        .map(OrderService.toShippingRuleOptionModel)
        .find((option) => option.isPickup)?.id === selectedShippingRuleId
    );
  }

  public get shippingRuleOptions(): ShippingRuleOptionModel[] {
    if (!this.cart?.shippingRuleInfo?.shippingRule?.options) {
      return [];
    }

    return this.cart.shippingRuleInfo?.shippingRule.options.map(OrderService.toShippingRuleOptionModel);
  }

  public get hasPickupOption(): boolean {
    return this.shippingRuleOptions.some((option) => option.isPickup);
  }

  public get hasEstimatedDestination(): boolean {
    return !!this.cartService.cart.destination?.country;
  }

  public get isEstimatedDestinationIsShippable() {
    return (
      this.hasEstimatedDestination &&
      (this.hasShippingRules || this.cartService.isDigitalCart || this.destinationWasChanged)
    );
  }

  public get taxOnProduct() {
    return this.storeSettingsService.getFetchedData().taxOnProduct;
  }

  public get shouldDisplayTax() {
    const {shouldShowTax} = this.styleSettingsService;

    return (
      shouldShowTax && !this.taxOnProduct && (!this.cartService.isDigitalCart || this.isEstimatedDestinationIsShippable)
    );
  }
}
