import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ICart, ICartProductsManifest} from '../../types/app.types';

export class ProductsService {
  private readonly siteStore: SiteStore;
  private sectionUrl: string;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
  }

  private async getSectionUrl(): Promise<string> {
    return (this.sectionUrl = (await this.siteStore.getSectionUrl(PageMap.PRODUCT)).url);
  }

  public async manifest(cart: ICart): Promise<ICartProductsManifest> {
    const sectionUrl = this.sectionUrl || (await this.getSectionUrl());

    return cart.items.reduce<ICartProductsManifest>((acc, item) => {
      // @todo use new CustomUrlApi
      acc[item.product.id] = {href: `${sectionUrl}/${item.product.urlPart}`};
      return acc;
    }, {});
  }
}
