import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ModalManager} from '@wix/wixstores-client-core/dist/es/src/modalManager/modalManager';

export class ModalManagerService {
  private readonly siteStore: SiteStore;
  public readonly modalManger: ModalManager;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.modalManger = new ModalManager(
      {
        openModal: (url: string, width: number, height: number) => {
          return this.siteStore.windowApis.openModal(url, {width, height, theme: 'BARE'});
        },
      },
      '//ecom.wix.com',
      this.siteStore.instanceManager.getInstance(),
      this.siteStore.experiments
    );
  }
}
